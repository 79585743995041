import type { BannerType, HomeVideoPage, HomeVideoType, VideoComParam, VideoCombinesParam } from './model'
import http from '~/utils/http'

// 广告位banner
export function getBannerList(params: BannerType) {
  return http.post<any>({
    url: '/video/home/queryVideoBannerList',
    body: params
  })
}

// 电影(电视剧/动漫/综艺)首页-推荐数据
export function getHomeVideoList(params: HomeVideoPage) {
  return http.post<any>({
    url: '/video/home/getHomeList',
    body: params
  })
}
// 电影(电视剧/动漫/综艺)分页列表
export function getHomeVideoPage(params: HomeVideoPage) {
  return http.post<any>({
    url: '/video/home/getVideoPage',
    body: params
  })
}
// 获取电影(电视剧/动漫/综艺)类型配置
export function getHomeVideoTypeList(params: HomeVideoType) {
  return http.post<any>({
    url: '/video/home/getVideoTypeList',
    body: params
  })
}
// 标签查询 /jav/main/tagQuery
export function tagQuery(params: any) {
  return http.post<any>({
    url: '/jav/main/tagQuery',
    body: params
  })
}

// 页签查询 /jav/main/tabQuery
export function tabQuery(params: any) {
  return http.post<any>({
    url: '/jav/main/tabQuery',
    body: params
  })
}

// 获取banner列表 /jav/main/queryBanner   ///客户端banner配置类型[0:首页滚动,1:首页固定,2:播放器固定,3:播放器暂停,5:我的,7:播放器相关影片,8:限免最新作品,9:限免最多观看,10:限免最多收藏,11-金刚区]
export function queryBanner(params: any) {
  return http.post<any>({
    url: '/jav/main/queryBanner',
    body: params
  })
}

// 广告位banner
export function queryBannerObj(params: BannerType) {
  return http.post<any>({
    url: '/jav/main/queryBanner',
    body: params
  })
}

// 片源组合
export function videoCom(params: VideoComParam) {
  return http.post<any>({
    url: '/jav/main/videoCom',
    body: params
  })
}

// 全部片源组合
export function videoComAll(params: VideoCombinesParam) {
  return http.post<any>({
    url: '/jav/main/videoComAll',
    body: params
  })
}
